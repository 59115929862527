function Header() {
    return ( 
        <>
        <section >
        <div className="header "><img src='../rentah_logo.png' className='rentah-logo-header'/> Rentah</div>
        </section>
        </>
     );
}

export default Header;