import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function User() {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [listing, setListing] = useState([]);
  const navigate =useNavigate()
  fetch(`https://web.rentah.com/api/users/${userId}`, {})
    .then((res) => {
      return res.json();
    })
    .then((response) => {
      if (response.status === true) {
        setUser(response.user);
        setListing(response.listings);
      }
    });
  return (
    <>
      <div className="">
        <div className="" style={{ height: 200, background: "#FFF298" }}></div>
        <div className="p-2">
          <div class="image-container">
            <img
              src={user?.profilePicture}
              alt="Profile Image"
              class="profile-image"
            />
          </div>
          <div
            className="mt-5 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <div class="fw-bold">{user?.fullName}</div>
              <div>member since 2023</div>
              <div>
                <i class="bi bi-telephone-fill me-2"></i>
                {user?.phone}
              </div>
            </div>
            <div>
              <i class="bi bi-geo-alt"></i>
              {user?.city} , {user?.state}
            </div>
          </div>
          <hr />
          <div>
            <p>{user?.description}</p>
          </div>
          <hr />
          <div style={{ borderBottom: "3px solid green" }}>
            <h6 className="text-center">Listings</h6>
          </div>
          <div
            className="d-flex"
            style={{ flexWrap: "wrap", justifyContent: "space-between" }}
          >
            {listing?.map((i) => (
          
               <div className="mt-4 text-dark" style={{ borderRadius: "10px",textDecoration:"none",cursor:"pointer" }} onClick={()=>{
                 navigate(`/${i._id}`)
               }}>
                <div>
                  <img
                    style={{
                      width: "160px",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThLYc78GrRv6REyaFlMmrkn8GA-a8llMpiSw&s"
                  />
                </div>
                <div className="mt-1 " style={{textDecoration:"none"}}>
                  <div className="fw-bold">${i?.budget}/{i?.listingType? "Rent" : "Sell"}</div>
                  <div className="">{i.title.slice(0, 20)}</div>
                  <div>
                    {" "}
                    <i class="bi bi-geo-alt"></i> {i?.location}
                  </div>
                  <div>
                    <i class="bi bi-person"></i>
                  </div>
                </div>
              </div>
           
             
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
